import { Avatar, Box, Button, Grid, Image, Layer, Text } from 'grommet';
import { Close, FormNext, FormPrevious } from 'grommet-icons';
import React from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from 'react-timeago/lib/language-strings/fr';
import logo from '../../../images/logo.jpeg';
import './index.css';

const formatter = buildFormatter(frenchStrings)

const Works = ({ onClick, posts, size }) => {

    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [subIndex, setSubIndex] = React.useState(0);

    const onImageClick = (index) => {
        setCurrentIndex(index);
        setOpen(true);
        setSubIndex(0);
    }

    const movePic = (slide) => {
        setCurrentIndex(currentIndex + slide);
        setSubIndex(0);
    }

    const moveSubPic = slide => {
        setSubIndex(subIndex + slide);
    }

    return (
        <Box gap="small">
            {open && size !== 'small' &&
                <Layer onEsc={() => setOpen(false)} onClickOutside={() => setOpen(false)} full>
                    <Button plain={true} icon={<Close />} onClick={() => setOpen(false)} style={{ position: 'absolute', top: 5, right: 5 }} />
                    <Box
                        direction="row"
                        gap="large"
                        style={{ width: '100%', height: '100%' }}
                        justify='between'
                        pad="medium"
                    >
                        {currentIndex > 0 ?
                            <Box style={{ marginRight: 20, opacity: 0.5 }} round
                                alignContent="center" alignSelf="center"
                                justify="center" align='center'
                                onClick={() => movePic(-1)}
                            >
                                <FormPrevious size='large' />
                            </Box> : <Box />
                        }
                        <Box direction='row'>
                            {subIndex > 0 && posts[currentIndex].links.length > 0 &&
                                <Box style={{ marginRight: -30, zIndex: 20, opacity: 0.8 }} round background='#ebebeb'
                                    alignContent="center" alignSelf="center"
                                    justify="center" align='center'
                                    onClick={() => moveSubPic(-1)}
                                >
                                    <FormPrevious size='medium' />
                                </Box>
                            }
                            <Image src={posts[currentIndex].links[subIndex]} fit='cover' />

                            {subIndex < posts[currentIndex].links.length - 1 &&
                                <Box style={{ marginLeft: -30, zIndex: 20, opacity: 0.8 }} round background='#ebebeb'
                                    alignContent="center" alignSelf="center"
                                    justify="center" align='center'
                                    onClick={() => moveSubPic(1)}
                                >
                                    <FormNext size='medium' />
                                </Box>
                            }

                            <Box style={{ maxWidth: 200, marginLeft: 20 }} gap="small">
                                <Box direction='row' align="center" gap="small">
                                    <Avatar src={logo} size='small' />
                                    <Text style={{ fontWeight: 'bold' }} size='small'>Be Perfect Manucure</Text>
                                </Box>
                                <Box align="center" gap="small">
                                    <Text size='small' style={{ color: '#00376b' }}>
                                        {
                                            posts[currentIndex].tags.map((t, i) => (
                                                <a style={{ wordWrap: 'break-word' }} key={i} size="xsmall">{t} </a>
                                            ))
                                        }
                                    </Text>

                                    <Text color='#8e8e8e' size='xsmall' textAlign="start"><TimeAgo date={posts[currentIndex].updatedAt} formatter={formatter} /></Text>
                                </Box>
                            </Box>
                        </Box>
                        {currentIndex < posts.length - 1 ?
                            <Box style={{ marginLeft: 20, opacity: 0.5 }}
                                alignContent="center" alignSelf="center"
                                justify="center" align='center'
                                onClick={() => movePic(1)}
                            >
                                <FormNext size='large' />
                            </Box> : <Box />
                        }
                    </Box>

                </Layer>
            }
            {open && size === 'small' &&
                <Layer style={{ overflowY: 'scroll' }} onEsc={() => setOpen(false)} full>
                    <Layer style={{ height: 40, width: '100%' }} position='top'>
                        <Box
                            onClick={() => setOpen(false)}
                            pad='medium'
                            direction='row'
                            justify='between'
                            align="center"
                            alignContent='center'
                            style={{ border: '1px solid #ebebeb' }}
                        >
                            <FormPrevious size='medium' style={{ position: 'sticky', position: '-webkit-sticky', top: 0, left: 0 }} />
                            <Text style={{ fontWeight: 'bold' }} textAlign="center">Be Perfect Manucure</Text>
                            <FormPrevious size='medium' color='#fff' />
                        </Box>
                    </Layer>
                    <ListMobile posts={posts} currentId={posts[currentIndex].id} />
                </Layer>

            }
            <Box direction='row' gap="small">
                <Grid columns={size === 'small' ? {size: 'auto', count: 3} :  {size: 'auto', count: 5}} gap={size === 'small' ? 'xxsmall' : "small"}>
                    {posts.map((img, index) => (
                        <Box key={index}
                            style={{
                                aspectRatio: '1',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                backgroundColor: '#000'
                            }}
                            className={'imageHover'}
                            onClick={() => onImageClick(index)}
                        >
                            <Image src={img.links[0]} fit="cover" />
                        </Box>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}

const ListMobile = ({ posts, currentId }) => {
    const refs = posts.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
    }, {});
    setTimeout(() => {
        if (!refs[currentId] || !refs[currentId].current) return;
        refs[currentId].current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }, 1000);
    return (
        <Box gap="small" style={{ marginTop: 40 }}>
            <ul style={{ width: '100%', padding: 0 }}>
                {
                    posts.map((post) => (
                        <li ref={refs[post.id]} style={{ marginTop: 12 }}>
                            <Box style={{ width: '100%' }} gap="small">
                                <Box direction='row' align="center" gap="small" pad="small">
                                    <Avatar src={logo} size='small' />
                                    <Text style={{ fontWeight: 'bold' }} size='small'>Be Perfect Manucure</Text>
                                </Box>
                                <Image src={post.links[0]} fit='cover' className='fullWidth' />
                                <Box gap="small" pad="small">

                                    <Box align="center" gap="small">
                                        <Text size='small' style={{ color: '#00376b' }}>
                                            {
                                                post.tags.map((t, i) => (
                                                    <a style={{ wordWrap: 'break-word' }} key={i} size="xsmall">{t} </a>
                                                ))
                                            }
                                        </Text>

                                        <Text color='#8e8e8e' size='xsmall' textAlign="start"><TimeAgo date={post.updatedAt} formatter={formatter} /></Text>
                                    </Box>
                                </Box>
                            </Box>
                        </li>
                    ))
                }
            </ul>
        </Box>
    )
}

export default Works;